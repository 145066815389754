<template>
<div class="row">
    <div class="col-12">
        <div class="row mb-3">
            <div class="col-lg-12">
                <div class="card w-100 bg-current p-lg-3 p-2 border-0 rounded-lg d-block float-left">
                    <img :src="'/images/teacher.png'" alt="icon" class="sm-mt-2 w75 position-relative top--25 float-left mr-2 mt--1 ">
                    <h2 class="display1-size display2-md-size d-inline-block float-left mb-0 text-white fw-700"><span class="font-xssss fw-700 text-white d-block mb-1 ml-1">Welcome to</span> People &amp; Group </h2>
                </div>
            </div>            
        </div> 
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                    <ul class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-around product-info-tab border-bottom-0" id="pills-tab" role="tablist">
                        <li class="list-inline-item active"><a class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase active" href="#navtabs0" data-toggle="tab">Everyone</a></li>
                        <li class="list-inline-item"><a class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#navtabs1" data-toggle="tab">Group</a></li>
                    </ul>
                </div>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="navtabs0">
                        <everyone></everyone>
                    </div>
                    <div class="tab-pane fade" id="navtabs1">
                        <group></group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Everyone from './Everyone.vue'
import Group from './Group.vue'

export default {
    components:{
        Everyone,
        Group,
    },
}
</script>
