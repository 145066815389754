<template>
    <div class="row">
        <div class="col-12">
            <div class="form-group p-3 border-light border p-2 shadow-xs bg-white rounded-lg">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="row m-0 p-0">    
                            <div class="form-group col-12 icon-input mb-0">
                                <i class="ti-reload font-xs text-grey-400"></i>
                                <input type="text" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100" :value="`Cycle ${cycle}`" disabled>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                    <div v-if="!isLoad" class="row justify-content-around">
                        <div v-if="group.length" class="card-body p-lg-3">
                            <div class="col-12">
                                <div class="card shadow-lg rounded-lg border-0 mb-4" v-for="(item, n) in group" :key="n">
                                    <div class="card-header border-0 bg-current py-3">
                                        <h4 class="font-sm mb-0 fw-700 text-white">{{item.name}}</h4>
                                    </div>
                                    <div v-for="(member, i) in item.member" :key="i" class="card-body">
                                        <span class="bg-current btn-round-xs rounded-lg font-xssss text-white fw-600">{{i + 1}}</span>
                                        <span class="font-xssss fw-500 text-black ml-2">{{member.user.name}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="card-body p-0">
                            <div class="col-12 text-center py-3">
                                <img :src="'/images/empty.png'" alt="" width="300">
                                <h1 class="text-muted">Data don't exist</h1>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row justify-content-around">
                        <div class="card-body p-0">
                            <div class="col-12 text-center py-3">
                                <div class="my-3">
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'Everyone',
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            isLoad: true,
            cycles: [],
            cycle: '',
            group:[],
        }
    },
    created(){
        this.getCycles()
    },
    methods:{
        async getGroup(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/people/group?slug=${this.paramsId}&cycle=${this.cycle}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.group = res.data.data
                this.isLoad = false
            })
        },
        async getCycles(){
            if(localStorage.getItem(`${this.paramsId}-cycles`)) {
                this.cycles = JSON.parse(localStorage.getItem(`${this.paramsId}-cycles`))
            } else {
                await axios.get(`${process.env.VUE_APP_URL_API}/core/course/cycles?slug=${this.paramsId}&is_active=true`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.cycles = res.data.data
                    for(let i = 0; i < this.cycles.length; i++) {
                        if(this.cycles[i].active) {
                            this.cycle = this.cycles[i].cycle
                            break;
                        }
                    }
                }).catch((err) => console.error(err))
            }
            if(localStorage.getItem(`${this.paramsId}-cycle`)) {
                this.cycle = localStorage.getItem(`${this.paramsId}-cycle`)
            } else {
                this.cycle = this.cycles[0].cycle
            }
            this.getGroup()
        },
        setCycle() {
            this.isLoad = true
            this.getGroup()
        },
    },
}
</script>